import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState('');
  const [isInteractionInProgress, setIsInteractionInProgress] = useState(false);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const initialize = async () => {
      if (accounts.length > 0) {
        const account = accounts[0];
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: account
          });
          const { idTokenClaims } = response;
          console.log('Successful authentication:', idTokenClaims);
          setUser({
            email: idTokenClaims.preferred_username,
            givenName: idTokenClaims.given_name,
            surname: idTokenClaims.family_name,
          });
          setLoginError('');
        } catch (error) {
          console.error('Silent token acquisition failed, falling back to popup:', error);
          try {
            const response = await instance.loginPopup(loginRequest);
            const { idTokenClaims } = response;
            console.log('Successful authentication via popup:', idTokenClaims);
            setUser({
              email: idTokenClaims.preferred_username,
              givenName: idTokenClaims.given_name,
              surname: idTokenClaims.family_name,
            });
            setLoginError('');
          } catch (error) {
            console.error('Authentication failed:', error);
            setLoginError('Authentication failed');
          }
        }
      }
    };

    initialize();
  }, [accounts, instance]);

const logout = () => {
  setUser(null);
  instance.logoutRedirect({
    onRedirectNavigate: () => false, // Prevents navigation to Microsoft logout page
  });
  window.location.reload(); // Redirect to the app page itself
};



  return (
    <UserContext.Provider value={{ user, loginError, setLoginError, isInteractionInProgress, setIsInteractionInProgress, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
